@if (!supportedBrowser) {
  <div id="BrowserNotSupported">
    <h1>Error!</h1><br/><br/>
    <div>This whistleblowing site is built with <a href="https://www.globaleaks.org" target="_blank">GlobaLeaks</a> that is a software designed to fully support all commonly used browsers on desktop PCs, laptops, tablets, and smartphones. Unfortunately, this browser appears to lack some necessary capabilities.<br/><br/>We recommend using the <a href="https://www.torproject.org/download/" target="_blank">Tor Browser</a>. We recommend using the Tor Browser for enhanced anonymity, as well as additional privacy and security features that are not available in other browsers.<br/><br/><a href="https://docs.globaleaks.org/en/stable/gettingstarted/SupportedBrowsers.html" target="_blank">Here</a>, you can find a list of other supported browsers.</div>
  </div>
}

@if (supportedBrowser) {
  <div class="container-fluid d-flex flex-column h-100 px-0">
    <div id="Skip"><a href="#Content" tabindex="0">{{'Skip to content'|translate}}</a></div>

    @if (showLoadingPanel) {
      <div [attr.data-cy]="'page-loader-overlay'" id="PageOverlay" [@fadeInOut]="!showLoadingPanel ? 'void' : ''">
        <div id="Loader"></div>
      </div>
    }

    @if (appDataService.public.node) {
      <div [dir]="utilsService.getDirection(translate.currentLang)" class="reveal flex-shrink-0" [ngClass]="{'public': isWhistleblowerPage(), 'block-user-input': showLoadingPanel}">
        @if (authenticationService.session?.properties?.operator_session) {
          <div>
            <src-operation></src-operation>
          </div>
        }
        @if (!appDataService.public.node.root_tenant && appDataService.public.node.mode === 'demo') {
          <div>
            <src-demo></src-demo>
          </div>
        }
        @if (isWhistleblowerPage() && location.hash!=='#/admin/' && !appDataService.public.node.disable_privacy_badge) {
          <div>
            <src-privacybadge></src-privacybadge>
          </div>
        }
        <views-header></views-header>
        <messageconsole id="MessagesConsoleBox"></messageconsole>
        <div id="ContentBox">
          <div class="row">
            @if (appConfig.sidebar && showSidebar) {
              <div id="Sidebar" class="col-lg-2 col-md-4 col-sm-12" role="navigation">
                <nav class="navbar navbar-expand-md p-0" role="navigation">
                  <div class="navbar-header">
                    <button type="button" class="navbar-toggler" (click)="isNavCollapsed = !isNavCollapsed">
                      <i class="fa-solid fa-bars"></i>
                    </button>
                  </div>
                  <div class="collapse navbar-collapse" [ngbCollapse]="isNavCollapsed">
                    <div class="navbar-nav">
                      @if (appConfig.sidebar==='recipient-sidebar') {
                        <src-receipt-sidebar class="nav-items"></src-receipt-sidebar>
                      }
                      @if (appConfig.sidebar==='custodian-sidebar') {
                        <src-custodian-sidebar class="nav-items"></src-custodian-sidebar>
                      }
                      @if (appConfig.sidebar==='analyst-sidebar') {
                        <src-analyst-sidebar class="nav-items"></src-analyst-sidebar>
                      }
                      @if (appConfig.sidebar==='admin-sidebar') {
                        <src-admin-sidebar class="nav-items"></src-admin-sidebar>
                      }
                    </div>
                  </div>
                </nav>
              </div>
            }
            <main id="Content" [ngClass]="{'col-lg-10 col-md-8 col-sm-12': appConfig.sidebar && showSidebar , 'col-md-12': !appConfig.sidebar}">
              <router-outlet></router-outlet>
            </main>
          </div>
        </div>
      </div>
      <app-footer class="mt-auto" dir="ltr"></app-footer>
    }
  </div>
}
